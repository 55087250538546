import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import countries from "assets/js/countries";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";

import Cookie from "js-cookie";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { Editor } from "@tinymce/tinymce-react";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import "moment/locale/fr";

import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
  },
  textField: {
    width: "100%",
  },
}));

const OfferForm = (props) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [offer, setOffer] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    title: "",
    permalink: "",
    meta_title: "",
    meta_description: "",
    short_description: "",
    description: "",
    lang: "FR",
    company: "",
    employment_type: "",
    job_location: "",
    job_address: "",
    job_city: "",
    job_zip: "",
    job_country_iso: "MA",
    image: null,
    img_alt: "",
    salary_value: "",
    salary_currency: "",
    salary_period: "",
    experience: "",
    expires_at: "",
    categories: [],
    visits: "",
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") setOffer(props.offer);
  }, []);

  const handleChange = (event) => {
    setOffer({
      ...offer,
      [event.target.name]: event.target.value,
    });
  };
  const handleDateChange = (momentDate) => {
    setOffer({
      ...offer,
      expires_at: momentDate.format("YYYY-MM-DD HH:mm:ss"),
    });
  };

  const handleFileChange = (event) => {
    setOffer({
      ...offer,
      image: event.target.files[0],
    });
  };

  const handleEditorChange = (content, editor) => {
    setOffer({
      ...offer,
      description: content,
    });
  };

  const handleCheckChange = (event) => {
    setOffer({
      ...offer,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    for (var key in offer) {
      formData.append(key, offer[key]);
    }

    if (props.action === "put") {
      formData.append("_method", "put");
    }
    let id = props.action === "put" ? "/" + offer.id : "";
    const options = {
      method: "POST",
      data: formData,
      url: "offer" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextValidator
            label="Titre *"
            className={classes.textField}
            onChange={handleChange}
            name="title"
            value={offer.title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Slug (permalink) *"
            onChange={handleChange}
            name="permalink"
            value={offer.permalink}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Meta title *"
            onChange={handleChange}
            name="meta_title"
            value={offer.meta_title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            onChange={handleFileChange}
            name="image *"
            type="file"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Catégories *"
            name="categories"
            value={offer.categories}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: true,
            }}
            errorMessages={[validations.required]}
          >
            {props.categories.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Meta description *"
            onChange={handleChange}
            name="meta_description"
            value={offer.meta_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Courte description *"
            multiline
            rows="2"
            onChange={handleChange}
            name="short_description"
            value={offer.short_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Visiteurs *"
            onChange={handleChange}
            name="visits"
            value={offer.visits}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Entreprise"
            onChange={handleChange}
            name="company"
            value={offer.company}
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Type de contrat *"
            name="employment_type"
            value={offer.employment_type}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: false,
            }}
            errorMessages={[validations.required]}
          >
            {props.employmentTypes.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Mode de travail *"
            name="job_location"
            value={offer.job_location}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: false,
            }}
            errorMessages={[validations.required]}
          >
            {props.jobLocations.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Adresse *"
            onChange={handleChange}
            name="job_address"
            value={offer.job_address}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Ville *"
            onChange={handleChange}
            name="job_city"
            value={offer.job_city}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Code postal *"
            onChange={handleChange}
            name="job_zip"
            value={offer.job_zip}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Pays "
            name="job_country_iso"
            value={offer.job_country_iso}
            onChange={handleChange}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: false,
            }}
          >
            {countries.map((c) => (
              <MenuItem key={c.value} value={c.value}>
                {c.text}
              </MenuItem>
            ))}
          </SelectValidator>
          {/* <Autocomplete
            id="search-by-country"
            options={countries}
            onChange={handleCountryIsoChange}
            getOptionLabel={(option) => option.text}
            inputValue={offer.job_country_iso?.text}
            renderOption={(option) => (
              <React.Fragment>{option.text}</React.Fragment>
            )}
            renderInput={(params) => (
              <TextField
                className={classes.textField}
                {...params}
                variant="outlined"
                size="small"
              />
            )}
          /> */}
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Période de salaire *"
            name="salary_period"
            value={offer.salary_period}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: false,
            }}
            errorMessages={[validations.required]}
          >
            {props.salaryPeriods.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Le salaire *"
            onChange={handleChange}
            name="salary_value"
            value={offer.salary_value}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Devise du salaire *"
            onChange={handleChange}
            name="salary_currency"
            value={offer.salary_currency}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            multiple
            fullWidth
            label="Expérience *"
            name="experience"
            value={offer.experience}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            SelectProps={{
              multiple: false,
            }}
            errorMessages={[validations.required]}
          >
            {props.experiences.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>

        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
              ampm={false}
              margin="dense"
              style={{ width: "100%" }}
              label="Date d'expiration de l'offre"
              inputVariant="outlined"
              value={offer.expires_at ? moment.utc(offer.expires_at) : null}
              onChange={(e) => handleDateChange(e)}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12}>
          <Editor
            initialValue={offer.description}
            apiKey="dbdpp75a6eec43uq835ypzu59kezpzd1u89zfinmea4vi60n"
            init={{
              height: 500,
              menubar: true,
              content_css_cors: true,
              content_css: ["/editor-styles.css", "description-area"],
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleEditorChange}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={offer.active}
                value={offer.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active"
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default OfferForm;
