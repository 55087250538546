import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import SpecsIcon from "@material-ui/icons/ListAlt";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswerOutlined";
import StarBorderIcon from "@material-ui/icons/StarBorderOutlined";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import ELearningSpecs from "modules/eLearning/ELearningSpecs";
import ELearningPrices from "modules/eLearning/ELearningPrices";
import ELearningReviews from "modules/eLearning/ELearningReviews";
import ELearningFaq from "modules/eLearning/ELearningFaq";
import Form from "modules/eLearning/ELearning";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const ELearningTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [eLearning, setELearning] = useState([]);
  const [categories, setCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const getList = () => {
    API.get("eLearning")
      .then(function(res) {
        setLoading(false);
        const pageLoad = Math.random()
          .toString(36)
          .substring(7);
        let list = res.data.list.map((l) => ({
          ...l,
          img_url:
            process.env.REACT_APP_API_PUBLIC_URL +
            "images/eLearnings/thumbs/" +
            l.img_name +
            "?" +
            pageLoad,
        }));

        setELearning(list);
        setCategories(res.data.categories);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setColumns([
      {
        title: "",
        field: "img_name",
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
        render: (data) => <img width="60px" src={data.img_url} />,
      },
      {
        title: "ID",
        field: "id",
        cellStyle: {
          width: 10,
          maxWidth: 10,
        },
        headerStyle: {
          width: 10,
          maxWidth: 10,
        },
      },
      {
        title: "Specs",
        field: "specs",
        render: (data) => (
          <IconButton
            onClick={() => openFullscreenDialog("specs", data)}
            component="span"
          >
            <SpecsIcon />
          </IconButton>
        ),
        sorting: false,
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "FAQs",
        sorting: false,
        render: (data) => (
          <IconButton
            onClick={() => openFullscreenDialog("faq", data)}
            component="span"
          >
            <QuestionAnswerIcon />
          </IconButton>
        ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "Reviews",
        sorting: false,
        render: (data) => (
          <IconButton
            onClick={() => openFullscreenDialog("reviews", data)}
            component="span"
          >
            <StarBorderIcon />
          </IconButton>
        ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "Tarification",
        sorting: false,
        render: (data) => (
          <IconButton
            onClick={() => openFullscreenDialog("prices", data)}
            component="span"
          >
            <MonetizationOnIcon />
          </IconButton>
        ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
      {
        title: "Titre",
        field: "title",
        cellStyle: {
          width: 350,
          maxWidth: 350,
        },
        headerStyle: {
          width: 350,
          maxWidth: 350,
        },
      },
      {
        title: "Visites",
        field: "visits",
        cellStyle: {
          width: 100,
          maxWidth: 100,
        },
        headerStyle: {
          width: 100,
          maxWidth: 100,
        },
      },
      {
        title: "Active",
        field: "active",
        initialEditValue: true,
        render: (data) =>
          data.active ? (
            <CheckIcon style={{ color: "green" }} />
          ) : (
            <ClearIcon style={{ color: "red" }} />
          ),
        cellStyle: {
          width: 80,
          maxWidth: 80,
        },
        headerStyle: {
          width: 80,
          maxWidth: 80,
        },
      },
    ]);

    if (ability.can("list", "eLearning")) {
      getList();
    }
  }, []);

  const addItem = (event) => {
    if (ability.can("create", "eLearning")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle formation",
          content: (
            <Form
              action={"post"}
              categories={categories}
              onChange={createELearning}
            />
          ),
        },
      });
    }
  };

  const openFullscreenDialog = (section, eLearning) => {
    if (section === "prices" && ability.can("list", "eLearningPrices"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les prix - " + eLearning.title,
          width: "full",
          content: <ELearningPrices eLearning={eLearning} />,
        },
      });
    else if (section === "specs" && ability.can("list", "eLearningSpecs"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les caractéristiques - " + eLearning.title,
          width: "full",
          content: <ELearningSpecs eLearning={eLearning} />,
        },
      });
    else if (section === "reviews" && ability.can("list", "eLearningReviews"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les avis - " + eLearning.title,
          width: "full",
          content: <ELearningReviews eLearning={eLearning} />,
        },
      });
    else if (section === "faq" && ability.can("list", "eLearningFaq"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les faqs - " + eLearning.title,
          width: "full",
          content: <ELearningFaq eLearning={eLearning} />,
        },
      });
  };

  const createELearning = () => {
    if (ability.can("create", "eLearning")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const updateELearning = () => {
    if (ability.can("update", "eLearning")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "eLearning")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          width: "full",
          title: rowData.title,
          content: (
            <Form
              action={"put"}
              eLearning={rowData}
              categories={categories}
              onChange={updateELearning}
            />
          ),
        },
      });
    }
  };

  return (
    <>
      {ability.can("list", "eLearning") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={eLearning}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "eLearning") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(oldData.id);
                      API.delete("eLearning/" + oldData.id)
                        .then(() => {
                          setELearning(
                            eLearning.filter((row) => row.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default ELearningTable;
