import React, { useEffect, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Cookie from "js-cookie";
import moment from "moment";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import Checkbox from "@material-ui/core/Checkbox";
import { Editor } from "@tinymce/tinymce-react";

import API from "api";

const containerCss = {
  root: {
    paddingTop: 20,
    background: "#fff",
    padding: "10px 20px",
    borderRadius: 5,
  },
  textField: {
    width: "100%",
  },
};
const containerCssStyles = makeStyles(containerCss);

const Deal = (props) => {
  const classes = containerCssStyles();
  const { dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [deal, setDeal] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    expires: new Date(),
    text: "",
    show_counter: true,
    active: true,
  });

  const [loading, setLoading] = useState(false);

  const getList = () => {
    API.get("deals")
      .then(function(res) {
        setLoading(false);
        setDeal(res.data);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    if (ability.can("list", "deal")) {
      getList();
    }
  }, []);

  const handleCheckChange = (event) => {
    setDeal({
      ...deal,
      [event.target.name]: event.target.checked,
    });
  };

  const handleExpiresChange = (d) => {
    setDeal({
      ...deal,
      expires: d,
    });
  };

  const handleEditorChange = (content, editor) => {
    setDeal({
      ...deal,
      text: content,
    });
  };

  const submitDeal = async (event) => {
    event.preventDefault();
    const options = {
      method: "PUT",
      data: deal,
      url: "deals/" + deal.id,
    };

    const content = validations.successfullyUpdated;

    API(options)
      .then(function(res) {
        getList();
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {ability.can("list", "deal") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <div className={classes.root}>
              <ValidatorForm onSubmit={submitDeal}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <DateTimePicker
                        ampm={false}
                        className={classes.textField}
                        label="Expire le"
                        inputVariant="outlined"
                        name="expires"
                        value={deal.expires ? moment.utc(deal.expires) : null}
                        onChange={(e) => handleExpiresChange(e)}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={12}>
                    <Editor
                      initialValue={deal.text}
                      apiKey="dbdpp75a6eec43uq835ypzu59kezpzd1u89zfinmea4vi60n"
                      init={{
                        height: 500,
                        menubar: true,
                        relative_urls: false, // to show emoticones
                        content_css: ["/editor-styles.css", "description-area"],
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        toolbar:
                          "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | link | help",
                      }}
                      onEditorChange={handleEditorChange}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="show_counter"
                          checked={deal.show_counter}
                          value={deal.show_counter}
                          onChange={handleCheckChange}
                          color="primary"
                        />
                      }
                      label="Afficher le compteur temps restant."
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="active"
                          checked={deal.active}
                          value={deal.active}
                          onChange={handleCheckChange}
                          color="primary"
                        />
                      }
                      label="Active."
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <AsyncButton
                      type="submit"
                      loading={loading}
                      title={"Sauvegarder"}
                      color="primary"
                    ></AsyncButton>
                  </Grid>
                </Grid>
              </ValidatorForm>
            </div>
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default Deal;
