import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import {
  ValidatorForm,
  TextValidator,
  SelectValidator,
} from "react-material-ui-form-validator";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Rating from "@material-ui/lab/Rating";
import { Editor } from "@tinymce/tinymce-react";
import Cookie from "js-cookie";
import AsyncButton from "components/AsyncButton/AsyncButton.js";
import validations from "assets/js/validations";
import API from "api";
import { withStyles } from "@material-ui/core/styles";

const StyledRating = withStyles({
  iconFilled: {
    color: "#ffb400",
    fontSize: "24px",
  },
  iconHover: {
    color: "#ffb400",
    fontSize: "24px",
  },
  iconEmpty: {
    color: "rgba(0, 0, 0, 0.26)",
    fontSize: "24px",
  },
})(Rating);

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    width: "450px",
  },
  textField: {
    width: "100%",
  },
}));

const SimulatorForm = (props) => {
  const classes = useStyles();

  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [simulator, setSimulator] = useState({
    lang_iso: Cookie.get("lang") ?? "fr",
    title: "",
    permalink: "",
    meta_title: "",
    meta_description: "",
    short_description: "",
    description: "",
    price_base: "",
    price: "",
    category_id: "",
    language: "fr",
    visits: "",
    duration: "",
    success_percent: "",
    prerequisites: "",
    vat_id: 1,
    image: null,
    prerequisites: "",
    active: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.action === "put") {
      props.simulator.price = props.simulator.price.value;
      props.simulator.price_base = props.simulator.price_base.value;
      setSimulator(props.simulator);
    }
  }, []);

  const handleChange = (event) => {
    setSimulator({
      ...simulator,
      [event.target.name]: event.target.value,
    });
  };

  const handleFileChange = (event) => {
    setSimulator({
      ...simulator,
      image: event.target.files[0],
    });
  };

  const handleCheckChange = (event) => {
    setSimulator({
      ...simulator,
      [event.target.name]: event.target.checked,
    });
  };

  const handleEditorChange = (content, editor) => {
    setSimulator({
      ...simulator,
      description: content,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    // Update the formData object
    const formData = new FormData();

    for (var key in simulator) {
      formData.append(key, simulator[key]);
    }

    if (props.action === "put") {
      formData.append("_method", "put");
    }
    let id = props.action === "put" ? "/" + simulator.id : "";
    const options = {
      method: "POST",
      data: formData,
      url: "simulators" + id,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    let content =
      props.action === "put"
        ? validations.successfullyUpdated
        : validations.successfullyAdded;
    API(options)
      .then(function(res) {
        props.onChange(res.data);
        dispatch({
          type: "OPEN_SNACKBAR",
          payload: {
            type: "success",
            content: content,
          },
        });
      })
      .catch((e) => {
        dispatch({
          type: "CATCH_ERROR",
          payload: e,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <ValidatorForm onSubmit={handleSubmit} className={classes.container}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextValidator
            label="Titre"
            className={classes.textField}
            onChange={handleChange}
            name="title"
            value={simulator.title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Slug (permalink)"
            onChange={handleChange}
            name="permalink"
            value={simulator.permalink}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Meta title"
            onChange={handleChange}
            name="meta_title"
            value={simulator.meta_title}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Prix de base"
            onChange={handleChange}
            value={simulator.price_base}
            name="price_base"
            variant="outlined"
            margin="dense"
            validators={["required", "isFloat"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Prix final"
            onChange={handleChange}
            name="price"
            value={simulator.price}
            variant="outlined"
            margin="dense"
            validators={["required", "isFloat"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>

        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Meta description"
            onChange={handleChange}
            name="meta_description"
            value={simulator.meta_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Courte description"
            onChange={handleChange}
            name="short_description"
            value={simulator.short_description}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            fullWidth
            label="Langue"
            name="language"
            value={simulator.language}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          >
            <MenuItem key={"fr"} value={"fr"}>
              Français
            </MenuItem>
            <MenuItem key={"en"} value={"en"}>
              Anglais
            </MenuItem>
          </SelectValidator>
        </Grid>
        <Grid item xs={12} md={6}>
          <SelectValidator
            className={classes.textField}
            fullWidth
            label="Catégories"
            name="category_id"
            value={simulator.category_id}
            onChange={handleChange}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          >
            {props.categories.map((c) => (
              <MenuItem key={c.id} value={c.id}>
                {c.title}
              </MenuItem>
            ))}
          </SelectValidator>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Visiteurs"
            onChange={handleChange}
            name="visits"
            value={simulator.visits}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            onChange={handleFileChange}
            name="image"
            type="file"
            variant="outlined"
            margin="dense"
          />
        </Grid>
        <Grid item xs={12}>
          <TextValidator
            className={classes.textField}
            label="Prérequis"
            onChange={handleChange}
            name="prerequisites"
            value={simulator.prerequisites}
            validators={["required"]}
            variant="outlined"
            margin="dense"
            errorMessages={[validations.required]}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Durée (En minute)"
            onChange={handleChange}
            name="duration"
            value={simulator.duration}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextValidator
            className={classes.textField}
            label="Pourcentage de réussite"
            onChange={handleChange}
            name="success_percent"
            value={simulator.success_percent}
            variant="outlined"
            margin="dense"
            validators={["required", "isNumber"]}
            errorMessages={[validations.required, validations.isNumber]}
          />
        </Grid>

        <Grid item xs={12}>
          <Editor
            initialValue={simulator.description}
            apiKey="dbdpp75a6eec43uq835ypzu59kezpzd1u89zfinmea4vi60n"
            init={{
              height: 500,
              menubar: true,
              content_css_cors: true,
              content_css: ["/editor-styles.css", "description-area"],
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic backcolor | \
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help",
            }}
            onEditorChange={handleEditorChange}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                name="active"
                checked={simulator.active}
                value={simulator.active}
                onChange={handleCheckChange}
                color="primary"
              />
            }
            label="Active."
          />
        </Grid>
        <Grid item xs={12}>
          <AsyncButton
            type="submit"
            loading={loading}
            title={"Sauvegarder"}
            color="primary"
          ></AsyncButton>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
};

export default SimulatorForm;
