import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import CircularProgress from "@material-ui/core/CircularProgress";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const ClientsTable = props => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [clients, setClients] = useState([]);

  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setLoading(true);

    if (ability.can("list", "users")) {
      API.get("clients")
        .then(function(res) {
          setLoading(false);
          setClients(res.data);
          setColumns([
            {
              title: "ID",
              field: "id",
              defaultSort: "desc",
              cellStyle: {
                width: 60,
                maxWidth: 60
              },
              headerStyle: {
                width: 60,
                maxWidth: 60
              }
            },
            {
              title: "Prénom",
              field: "fname",
              cellStyle: {
                width: 80,
                maxWidth: 80
              },
              headerStyle: {
                width: 80,
                maxWidth: 80
              }
            },
            {
              title: "Nom",
              field: "lname",
              cellStyle: {
                width: 80,
                maxWidth: 80
              },
              headerStyle: {
                width: 80,
                maxWidth: 80
              }
            },
            {
              title: "Email",
              field: "email",
              cellStyle: {
                width: 200,
                maxWidth: 200
              },
              headerStyle: {
                width: 200,
                maxWidth: 200
              }
            },
            {
              title: "Téléphone",
              field: "phone",
              cellStyle: {
                width: 100,
                maxWidth: 100
              },
              headerStyle: {
                width: 100,
                maxWidth: 100
              }
            },
            {
              title: "Fonction",
              field: "function",
              cellStyle: {
                width: 100,
                maxWidth: 100
              },
              headerStyle: {
                width: 100,
                maxWidth: 100
              }
            },
            {
              title: "Offres",
              field: "offers",
              render: data =>
                data.offers ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : (
                  <ClearIcon style={{ color: "red" }} />
                ),
              cellStyle: {
                width: 80,
                maxWidth: 80
              },
              headerStyle: {
                width: 80,
                maxWidth: 80
              }
            },
            {
              title: "Vérifié",
              field: "verified",
              render: data =>
                data.verified ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : (
                  <ClearIcon style={{ color: "red" }} />
                ),
              cellStyle: {
                width: 80,
                maxWidth: 80
              },
              headerStyle: {
                width: 80,
                maxWidth: 80
              }
            },
            {
              title: "Active",
              field: "active",
              render: data =>
                data.active ? (
                  <CheckIcon style={{ color: "green" }} />
                ) : (
                  <ClearIcon style={{ color: "red" }} />
                ),
              cellStyle: {
                width: 80,
                maxWidth: 80
              },
              headerStyle: {
                width: 80,
                maxWidth: 80
              }
            }
          ]);
        })
        .catch(e => {
          dispatch({ type: "CATCH_ERROR", payload: e });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  return (
    <>
      {ability.can("list", "users") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto"
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              columns={columns}
              data={clients}
              options={{
                exportButton: true,
                sorting:true
              }}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                ))
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page"
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche"
                },
                header: {
                  actions: "Actions"
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter"
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder"
                  }
                }
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default ClientsTable;
