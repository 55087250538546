import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileCopy from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswerOutlined";
import StarBorderIcon from "@material-ui/icons/StarBorderOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import PeopleIcon from "@material-ui/icons/People";
import PublishIcon from "@material-ui/icons/Publish";
import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import SimulatorExercices from "modules/simulator/SimulatorExercices";
import SimulatorBulkExercices from "modules/simulator/SimulatorBulkExercices";
import SimulatorUsers from "modules/simulator/SimulatorUsers";
import SimulatorReviews from "modules/simulator/SimulatorReviews";
import SimulatorPrices from "modules/simulator/SimulatorPrices";
import Form from "forms/Simulator";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const SimulatorsTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [simulators, setSimulators] = useState([]);
  const [categories, setCategories] = useState([]);
  const [copiedSalesforceId, setCopiedSalesforceId] = useState("nothing");
  const [loading, setLoading] = useState(false);

  const tableColumns = [
    {
      title: "",
      field: "img_name",
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
      render: (data) => <img width="60px" src={data.img_url} />,
    },
    {
      title: "ID",
      field: "id",
      cellStyle: {
        width: 10,
        maxWidth: 10,
      },
      headerStyle: {
        width: 10,
        maxWidth: 10,
      },
    },
    {
      title: "Salesforce",
      field: "salesforce_id",
      render: (data) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: 8 }}>{data.salesforce_id}</span>
          {copiedSalesforceId == data.salesforce_id ? (
            <Tooltip title="Copié">
              <IconButton size="small" component="span">
                <CheckIcon size="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Copier">
              <IconButton
                size="small"
                onClick={() => copyToClipBoard(data.salesforce_id)}
                component="span"
              >
                <FileCopy size="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
      cellStyle: {
        width: 180,
        maxWidth: 180,
      },
      headerStyle: {
        width: 180,
        maxWidth: 180,
      },
    },
    {
      title: "Bulk",
      field: "bulk",
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("bulk", data)}
          component="span"
        >
          <PublishIcon />
        </IconButton>
      ),
      sorting: false,
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "Exercices",
      field: "exercices",
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("exercices", data)}
          component="span"
        >
          <QuestionAnswerIcon />
        </IconButton>
      ),
      sorting: false,
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "Reviews",
      sorting: false,
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("reviews", data)}
          component="span"
        >
          <StarBorderIcon />
        </IconButton>
      ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "Clients",
      sorting: false,
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("users", data)}
          component="span"
        >
          <PeopleIcon />
        </IconButton>
      ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "Tarification",
      sorting: false,
      render: (data) => (
        <IconButton
          onClick={() => openFullscreenDialog("prices", data)}
          component="span"
        >
          <MonetizationOnIcon />
        </IconButton>
      ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
    {
      title: "Titre",
      field: "title",
      cellStyle: {
        width: 350,
        maxWidth: 350,
      },
      headerStyle: {
        width: 350,
        maxWidth: 350,
      },
    },
    // {
    //   title: "Réussite",
    //   field: "success_percent",
    //   render: (data) => <span>{data.success_percent} %</span>,
    //   cellStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    //   headerStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    // },
    // {
    //   title: "Durée",
    //   field: "duration",
    //   cellStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    //   headerStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    // },
    // {
    //   title: "Visites",
    //   field: "visits",
    //   cellStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    //   headerStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    // },
    {
      title: "Active",
      field: "active",
      initialEditValue: true,
      render: (data) =>
        data.active ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
  ];

  const getList = () => {
    API.get("simulators")
      .then(function(res) {
        setLoading(false);
        const pageLoad = Math.random()
          .toString(36)
          .substring(7);
        let list = res.data.list.map((l) => ({
          ...l,
          img_url:
            process.env.REACT_APP_API_PUBLIC_URL +
            "images/simulators/thumbs/" +
            l.img_name +
            "?" +
            pageLoad,
        }));
        setSimulators(list);
        setCategories(res.data.categories);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setColumns(tableColumns);

    if (ability.can("list", "simulators")) {
      getList();
    }
  }, []);

  useEffect(() => {
    setColumns(tableColumns);
  }, [copiedSalesforceId]);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopiedSalesforceId(copyMe);
    } catch (err) {
      alert("Erreur de copie!");
    }
  };

  const addItem = (event) => {
    if (ability.can("create", "simulators")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouveau simulateur",
          content: (
            <Form
              action={"post"}
              categories={categories}
              onChange={createSimulator}
            />
          ),
        },
      });
    }
  };

  const openFullscreenDialog = (section, simulator) => {
    if (section === "bulk" && ability.can("list", "simulatorExercices"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Importation d'un exercice en masse - " + simulator.title,
          width: "full",
          content: <SimulatorBulkExercices simulator={simulator} />,
        },
      });
    else if (
      section === "exercices" &&
      ability.can("list", "simulatorExercices")
    )
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les exercices - " + simulator.title,
          width: "full",
          content: <SimulatorExercices simulator={simulator} />,
        },
      });
    else if (section === "reviews" && ability.can("list", "simulatorReviews"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les avis - " + simulator.title,
          width: "full",
          content: <SimulatorReviews simulator={simulator} />,
        },
      });
    else if (section === "users" && ability.can("list", "simulatorUsers"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les clients du " + simulator.title,
          width: "full",
          content: <SimulatorUsers simulator={simulator} />,
        },
      });
    else if (section === "prices" && ability.can("list", "simulatorPrices"))
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          title: "Les prix - " + simulator.title,
          width: "full",
          content: <SimulatorPrices simulator={simulator} />,
        },
      });
  };

  const createSimulator = () => {
    if (ability.can("create", "simulators")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const updateSimulator = () => {
    if (ability.can("update", "simulators")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "simulators")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: rowData.title,
          content: (
            <Form
              action={"put"}
              simulator={rowData}
              categories={categories}
              onChange={updateSimulator}
            />
          ),
        },
      });
    }
  };

  return (
    <>
      {ability.can("list", "simulators") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={simulators}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "simulators") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      console.log(oldData.id);
                      API.delete("simulators/" + oldData.id)
                        .then(() => {
                          setSimulators(
                            simulators.filter((row) => row.id !== oldData.id)
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default SimulatorsTable;
