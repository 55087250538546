import React, { useEffect, forwardRef, useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FileCopy from "@material-ui/icons/FileCopy";
import Tooltip from "@material-ui/core/Tooltip";

import MaterialTable from "material-table";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswerOutlined";
import CircularProgress from "@material-ui/core/CircularProgress";
import Rating from "@material-ui/lab/Rating";
import IconButton from "@material-ui/core/IconButton";
import CertifFaq from "forms/CertifFaq";

import { GlobalContext } from "context/GlobalContext";
import { AbilityContext } from "context/AbilityContext";
import Form from "forms/Certification";
import API from "api";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

const CertificationsTable = (props) => {
  const { state, dispatch } = useContext(GlobalContext);
  const ability = useContext(AbilityContext);
  const [columns, setColumns] = useState([]);
  const [certifications, setCertifications] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [copiedSalesforceId, setCopiedSalesforceId] = useState("nothing");

  const tableColumns = [
    {
      title: "",
      field: "img_name",
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
      render: (data) => <img width="60px" src={data.img_url} />,
    },
    {
      title: "ID",
      field: "id",
      cellStyle: {
        width: 10,
        maxWidth: 10,
      },
      headerStyle: {
        width: 10,
        maxWidth: 10,
      },
    },
    {
      title: "Salesforce",
      field: "salesforce_id",
      render: (data) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span style={{ marginRight: 8 }}>{data.salesforce_id}</span>
          {copiedSalesforceId == data.salesforce_id ? (
            <Tooltip title="Copié">
              <IconButton size="small" component="span">
                <CheckIcon size="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Copier">
              <IconButton
                size="small"
                onClick={() => copyToClipBoard(data.salesforce_id)}
                component="span"
              >
                <FileCopy size="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      ),
      cellStyle: {
        width: 200,
        maxWidth: 200,
      },
      headerStyle: {
        width: 200,
        maxWidth: 200,
      },
    },
    {
      title: "FAQs",
      sorting: false,
      render: (data) => (
        <IconButton onClick={() => openFullscreenDialog(data)} component="span">
          <QuestionAnswerIcon />
        </IconButton>
      ),
      cellStyle: {
        width: 60,
        maxWidth: 60,
      },
      headerStyle: {
        width: 60,
        maxWidth: 60,
      },
    },
    {
      title: "Note",
      field: "rating",
      render: (data) => (
        <Rating name="read-only" value={data.rating} readOnly />
      ),
      cellStyle: {
        width: 140,
        maxWidth: 140,
      },
      headerStyle: {
        width: 140,
        maxWidth: 140,
      },
    },
    {
      title: "Prix",
      field: "price",
      render: (data) => (
        <span>
          {data.price_base > data.price ? (
            <strike>
              <small>{data.price_base} DH</small>
            </strike>
          ) : null}
          {" " + data.price} DH
        </span>
      ),
      cellStyle: {
        width: 350,
        maxWidth: 350,
      },
      headerStyle: {
        width: 350,
        maxWidth: 350,
      },
    },
    {
      title: "Titre",
      field: "title",
      cellStyle: {
        width: 350,
        maxWidth: 350,
      },
      headerStyle: {
        width: 350,
        maxWidth: 350,
      },
    },
    // {
    //   title: "Prérequis",
    //   field: "prerequisites",
    //   render: (data) =>
    //     data.prerequisites ? (
    //       <CheckIcon style={{ color: "green" }} />
    //     ) : (
    //       <ClearIcon style={{ color: "red" }} />
    //     ),
    //   cellStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    //   headerStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    // },
    // {
    //   title: "Evaluation",
    //   field: "evaluation",
    //   cellStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    //   headerStyle: {
    //     width: 80,
    //     maxWidth: 80,
    //   },
    // },
    // {
    //   title: "Visites",
    //   field: "visits",
    //   cellStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    //   headerStyle: {
    //     width: 100,
    //     maxWidth: 100,
    //   },
    // },
    {
      title: "Active",
      field: "active",
      initialEditValue: true,
      render: (data) =>
        data.active ? (
          <CheckIcon style={{ color: "green" }} />
        ) : (
          <ClearIcon style={{ color: "red" }} />
        ),
      cellStyle: {
        width: 80,
        maxWidth: 80,
      },
      headerStyle: {
        width: 80,
        maxWidth: 80,
      },
    },
  ];

  const getList = () => {
    API.get("certifs")
      .then(function(res) {
        setLoading(false);
        const pageLoad = Math.random()
          .toString(36)
          .substring(7);
        let list = res.data.list.map((l) => ({
          ...l,
          img_url:
            process.env.REACT_APP_API_PUBLIC_URL +
            "images/certifications/thumbs/" +
            l.img_name +
            "?" +
            pageLoad,
        }));
        setCertifications(list);
        setCategories(res.data.categories);
      })
      .catch((e) => {
        dispatch({ type: "CATCH_ERROR", payload: e });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    setColumns(tableColumns);

    if (ability.can("list", "certifs")) {
      getList();
    }
  }, []);

  useEffect(() => {
    setColumns(tableColumns);
  }, [copiedSalesforceId]);

  const copyToClipBoard = async (copyMe) => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopiedSalesforceId(copyMe);
    } catch (err) {
      alert("Erreur de copie!");
    }
  };

  const openFullscreenDialog = (certif) => {
    if (ability.can("list", "certifFaq")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          width: "full",
          title: "Les faqs - " + certif.title,
          content: <CertifFaq certif={certif} />,
        },
      });
    }
  };

  const addItem = (event) => {
    if (ability.can("create", "certifs")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: "Nouvelle certification",
          content: (
            <Form
              action={"post"}
              categories={categories}
              onChange={createCertif}
            />
          ),
        },
      });
    }
  };

  const createCertif = (certif) => {
    if (ability.can("create", "certifs")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const updateCertif = (certif) => {
    if (ability.can("update", "certifs")) {
      dispatch({
        type: "CLOSE_DRAWER",
      });
      getList();
    }
  };

  const editItem = (event, rowData) => {
    if (ability.can("update", "certifs")) {
      dispatch({
        type: "OPEN_DRAWER",
        payload: {
          event: event,
          title: rowData.title,
          content: (
            <Form
              action={"put"}
              certif={rowData}
              categories={categories}
              onChange={updateCertif}
            />
          ),
        },
      });
    }
  };
  return (
    <>
      {ability.can("list", "certifs") ? (
        <>
          {loading ? (
            <CircularProgress
              thickness={2}
              style={{
                width: "100px",
                height: "100px",
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: "auto",
              }}
            />
          ) : (
            <MaterialTable
              title={null}
              options={{ pageSize: 3, pageSizeOptions: [1, 3, 5, 10, 20] }}
              columns={columns}
              data={certifications}
              defaultSorted={[
                {
                  id: "title",
                  desc: true,
                },
              ]}
              editable={
                ability.can("delete", "certifs") && {
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      API.delete("certifs/" + oldData.id)
                        .then(() => {
                          setCertifications(
                            certifications.filter(
                              (row) => row.id !== oldData.id
                            )
                          );
                          resolve();
                        })
                        .catch((e) => {
                          dispatch({ type: "CATCH_ERROR", payload: e });
                          reject();
                        });
                    }),
                }
              }
              options={{
                exportButton: true,
              }}
              actions={[
                {
                  icon: "edit",
                  tooltip: "Modifier",
                  onClick: (event, rowData) => editItem(event, rowData),
                },
                {
                  icon: "add",
                  isFreeAction: true,
                  onClick: (event) => addItem(event),
                },
              ]}
              icons={{
                SortArrow: forwardRef((props, ref) => (
                  <KeyboardArrowDown {...props} ref={ref} />
                )),
              }}
              localization={{
                pagination: {
                  labelDisplayedRows: "{from}-{to} de {count}",
                  labelRowsSelect: "Lignes",
                  labelRowsPerPage: "Ligne par page",
                  firstAriaLabel: "Première page",
                  firstTooltip: "Première page",
                  previousAriaLabel: "Page précédente",
                  previousTooltip: "Page précédente",
                  nextAriaLabel: "Page suivante",
                  nextTooltip: "Page suivante",
                  lastAriaLabel: "Dernière page",
                  lastTooltip: "Dernière page",
                },
                toolbar: {
                  exportTitle: "Exporter",
                  exportName: "Exporter en format CSV",
                  searchTooltip: "Recherche",
                  searchPlaceholder: "Recherche",
                },
                header: {
                  actions: "Actions",
                },
                body: {
                  addTooltip: "Ajouter",
                  deleteTooltip: "Supprimer",
                  editTooltip: "Modifier",
                  emptyDataSourceMessage: "Aucun résultat trouvé",
                  filterRow: {
                    filterTooltip: "Filter",
                  },
                  editRow: {
                    deleteText: "Êtes-vous sûr de supprimer cette ligne?",
                    cancelTooltip: "Annuler",
                    saveTooltip: "Sauvegarder",
                  },
                },
              }}
            />
          )}
        </>
      ) : (
        "Oups...Vous avez pas accès a ce module."
      )}
    </>
  );
};

export default CertificationsTable;
