// Pages Icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import LockIcon from "@material-ui/icons/Lock";
import PeopleIcon from "@material-ui/icons/PeopleOutlineOutlined";
import SupervisedUserCircleIcon from "@material-ui/icons/SupervisedUserCircle";
import SchoolIcon from "@material-ui/icons/School";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import StoreIcon from "@material-ui/icons/Store";
import CoursesIcon from "@material-ui/icons/ImportContacts";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import PermPhoneMsgSharpIcon from "@material-ui/icons/PermPhoneMsgSharp";
import Power from "@material-ui/icons/PowerSettingsNew";
import ExamsIcon from "@material-ui/icons/Dvr";
import BcloudBusinessIcon from "@material-ui/icons/InsertDriveFile";
import TrainersIcon from "@material-ui/icons/School";
import LeCloudIcon from "@material-ui/icons/Category";
import SettingsIcon from "@material-ui/icons/Settings";
import ImageIcon from "@material-ui/icons/Image";
import SmartBarIcon from "@material-ui/icons/BlurLinear";
import SiteIcon from "@material-ui/icons/Language";
import OfferIcon from "@material-ui/icons/Work";

// Pages views
import Callbacks from "pages/Callbacks.js";
import Certifications from "pages/Certifications.js";
import CertificationCategories from "pages/CertificationCategories.js";
import Courses from "pages/Courses.js";
import CourseCategories from "pages/CourseCategories.js";
import ELearning from "pages/ELearning.js";
import ELearningCategories from "pages/ELearningCategories.js";
import Currencies from "pages/Currencies.js";
import Dashboard from "pages/Dashboard.js";
import Orders from "pages/Orders.js";
import RolesPermissions from "pages/RolesPermissions.js";
import Vouchers from "pages/Vouchers.js";
import Managers from "pages/Managers.js";
import Clients from "pages/Clients.js";
import Login from "pages/Login.js";
import Simulators from "pages/Simulators.js";
import SimulatorCategories from "pages/SimulatorCategories.js";
import BcloudBusiness from "pages/BcloudBusiness.js";
import Trainers from "pages/Trainers.js";
import Banners from "pages/Banners.js";
import Slides from "pages/Slides.js";
import Deal from "pages/Deal.js";
import Site from "pages/Sites.js";
import Offers from "pages/Offers.js";
import OffersCategories from "pages/OffersCategories.js";

var dashRoutes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Utilisateurs bcloud",
    icon: PeopleIcon,
    state: "usersCollapse",
    views: [
      {
        collapse: true,
        name: "Agents bcloud",
        icon: PeopleIcon,
        state: "agentsCollapse",
        views: [
          {
            path: "/managers",
            name: "Liste des agents",
            icon: PeopleIcon,
            component: Managers,
            layout: "/admin",
          },
          {
            path: "/roles-permissions",
            name: "Rôles et permissions",
            icon: LockIcon,
            component: RolesPermissions,
            layout: "/admin",
          },
        ],
      },
      {
        path: "/clients",
        name: "Clients bcloud",
        icon: SupervisedUserCircleIcon,
        component: Clients,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Catalogue Bcloud",
    icon: LeCloudIcon,
    state: "catalogCollapse",
    views: [
      {
        collapse: true,
        name: "E-Learning",
        icon: LeCloudIcon,
        state: "eLearningCollapse",
        views: [
          {
            path: "/e-learning",
            name: "Liste e-learning",
            icon: LeCloudIcon,
            component: ELearning,
            layout: "/admin",
          },
          {
            path: "/e-learning-categories",
            name: "Les catégories",
            icon: LeCloudIcon,
            component: ELearningCategories,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Les formations",
        icon: CoursesIcon,
        state: "coursesCollapse",
        views: [
          {
            path: "/courses",
            name: "Liste des formations",
            icon: SchoolIcon,
            component: Courses,
            layout: "/admin",
          },
          {
            path: "/course-categories",
            name: "Les catégories",
            icon: SchoolIcon,
            component: CourseCategories,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Les simulateurs",
        icon: ExamsIcon,
        state: "simulatorsCollapse",
        views: [
          {
            path: "/simulators",
            name: "Liste des simulateurs",
            icon: ExamsIcon,
            component: Simulators,
            layout: "/admin",
          },
          {
            path: "/simulator-categories",
            name: "Les catégories",
            icon: ExamsIcon,
            component: SimulatorCategories,
            layout: "/admin",
          },
        ],
      },
      {
        collapse: true,
        name: "Les certifications",
        icon: CardMembershipIcon,
        state: "certificationsCollapse",
        views: [
          {
            path: "/certifications",
            name: "Liste des certifications",
            icon: SchoolIcon,
            component: Certifications,
            layout: "/admin",
          },
          {
            path: "/certifications-categories",
            name: "Les catégories",
            icon: SchoolIcon,
            component: CertificationCategories,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  {
    collapse: true,
    name: "Recrutement",
    icon: OfferIcon,
    state: "offersCollapse",
    views: [
      {
        path: "/offers",
        name: "Liste des offres",
        icon: OfferIcon,
        component: Offers,
        layout: "/admin",
      },
      {
        path: "/list-offers-categories",
        name: "Les catégories",
        icon: OfferIcon,
        component: OffersCategories,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Services bcloud",
    icon: PeopleIcon,
    state: "servicesCollapse",
    views: [
      {
        path: "/formateurs",
        name: "Formateurs Bcloud",
        icon: TrainersIcon,
        component: Trainers,
        layout: "/admin",
      },
      {
        path: "/bcloud-business",
        name: "Demandes de devis",
        icon: BcloudBusinessIcon,
        component: BcloudBusiness,
        layout: "/admin",
      },

      {
        path: "/callbacks",
        name: "Demandes de contact",
        icon: PermPhoneMsgSharpIcon,
        component: Callbacks,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Ecommerce",
    icon: StoreIcon,
    state: "ecommerceCollapse",
    views: [
      {
        path: "/orders",
        name: "Commandes Bcloud",
        icon: ShoppingCartIcon,
        component: Orders,
        layout: "/admin",
      },
      {
        path: "/currencies",
        name: "Les devises Bcloud",
        icon: MonetizationOnOutlinedIcon,
        component: Currencies,
        layout: "/admin",
      },
      {
        path: "/vouchers",
        name: "Les coupons Bcloud",
        icon: LocalOfferIcon,
        component: Vouchers,
        layout: "/admin",
      },
    ],
  },

  {
    collapse: true,
    name: "Paramètres",
    icon: SettingsIcon,
    state: "settingsCollapse",
    views: [
      {
        path: "/banners",
        name: "Bannières du site",
        icon: ImageIcon,
        component: Banners,
        layout: "/admin",
      },
      {
        path: "/slides",
        name: "Les slides du site",
        icon: ImageIcon,
        component: Slides,
        layout: "/admin",
      },
      {
        path: "/smart-bar",
        name: "Barre de promotion",
        icon: SmartBarIcon,
        component: Deal,
        layout: "/admin",
      },
      {
        path: "/sites",
        name: "Les TLDs liés",
        icon: SiteIcon,
        component: Site,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/login",
    name: "Se déconnecter",
    component: Login,
    icon: Power,
    layout: "/auth",
  },
];
export default dashRoutes;
